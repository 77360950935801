.align_center {
  align-self: center;
}
.zero_margin_bottom {
  margin-bottom: 0;
}

.margin-left {
  margin-left: 3%;
}

.red_table_header {
  background-color: rgb(244, 204, 204);
}

.show_only[readonly]   {
    background-color: #fff;
    opacity: 1;
    border: none;
}
.upload-blog-a a{
  /* background-color: #1abc9c;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 2px;
    color: white; */
}
.upload-blog-a h4{
  margin-top:40px;
  margin-bottom: 20px;
}
.upload-blog-a img{
  margin:30px
}
.upload-blog-a div{
  margin-bottom: 30px;
}
.route_permission{
  width: 60%;
  display:flex; justify-content:left; align-items: centre;
}
.route_permission > input{
  height:25px;
}
.route_permission > p:nth-child(4){
  width:20%
}
.route_permission > button{
  height: 25px;
  background-color:warning
}
.route_permission > *
{
  width: 20%;
}

@media (min-width:720px) {
  .upload_image {
    grid-template-columns: 30% 70%;
  }
}
.upload_image{
  display: grid;
}