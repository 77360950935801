.highlight_orange {
  background: #fce8b2;
  padding: 1px;
  border: #00cc00 dotted 1px;
}
.highlight_green {
  background: #b7e1cd;
  padding: 1px;
  border: #00cc00 dotted 1px;
}
.highlight_red {
  background: #f4cccc;
  padding: 1px;
  border: #00cc00 dotted 1px;
}
.highlight_dark_red {
  background: #f16969;
  padding: 1px;
  border: #00cc00 dotted 1px;
}
.highlight_clicked_row {
  background-color: #96d1ff;
}
.highlight_order_found_row {
  background-color: #f2f2d5;
}
